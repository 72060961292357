/* eslint-disable react/no-danger, jsx-a11y/anchor-is-valid, react/forbid-prop-types */
import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { useSelector } from "react-redux"
import classNames from "classnames"
import {
  Button,
  Accordion,
  BackLink,
  usePopup,
  Title,
  Loading,
  Progress,
  ForwardLink,
  LinkWrapper,
  BlockContent,
} from "../../../components"
import SEO from "../../../components/SEO"
import styles from "./style.module.scss"
import { IndustryType, SubjectType } from "../../../propTypes"
import { createSubjectImportance } from "../../../state/subjectImportance/actions"
import { CONFIG } from "../../../constants"
import { withDefaults, useDispatch } from "../../../utils"
import withPageDependencies from "../../../hoc/withPageDependencies"
import useSanity from "../../../hooks/useSanity"
import { blockContentGroq } from "../../../groq"
import { H, Section } from "../../../state/levelContext"
// import LinkWrapper from "../../../components/linkWrapper"

const Introduksjon = ({ industry, subject }) => {
  const state = useSelector(s => s.subjectImportance)
  const subjectImportance = Object.values(state).find(
    s => s.industryId === industry._id && s.subjectId === subject._id
  )
  const dispatch = useDispatch()

  const onCreateSubjectImportance = value => {
    dispatch(createSubjectImportance(industry._id, subject._id, value))
  }

  useEffect(() => {
    if (!subjectImportance) {
      onCreateSubjectImportance(CONFIG.DEFAULT_SLIDER_VALUE) // If the user do not touch the slider, and accepts default value of 3, we must save the default value (3) to the state
    }
  })
  const [ZeroImportancePopup, setPopupOpen] = usePopup()

  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      type: "hjelpen_forbedringspunkterIntroPage",
      statusViserDataType: "hjelpen_statusViser",
      industryTag: industry.tag,
      subjectTag: subject.tag,
    })
  }, [industry, subject])

  const { loading, error, data } = useSanity(
    `{
      "pageData": *[_type==$type &&
        industry->tag in [$industryTag, "default"] &&
        subject->tag == $subjectTag &&
        count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
          tag,
          title,
        },
        heroImage {
          ...,
          asset-> {
            url
          },
        },
        subject-> {
          tag,
          title,
        },
        "pastandInnhold": coalesce(pastandInnhold[] ${blockContentGroq}, []),
        "examplesContent": coalesce(examplesContent[] ${blockContentGroq}, []),
        "formulationItemsRefs": coalesce(formulationItemsRef[]-> { _type, _id, title }, []), 
      },
      "statusviser": *[_type==$statusViserDataType &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0]  {
          ...,
          industry-> {
            tag,
            title,
          },
        },
    }`,
    params
  )
  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data.pageData, industry.tag)
  const statusLabels = withDefaults(data.statusviser, industry.tag)

  return (
    <>
      <SEO title={subject.title} />

      {page ? (
        <div className="content">
          <div className={classNames("desktop-col-12", styles.introHero)}>
            <Progress activeStep={1} subject={subject} industry={industry} labels={statusLabels} />
            <div className={styles.header}>
              <Title.Subject title={page.smallTitle} subject={subject} />
            </div>
          </div>

          <Section>
            <div className="desktop-col-8">
              <div className={styles.accordion}>
                {page.showTipsAccordian && page.tipsTitle && (
                  <Accordion.Default
                    items={[
                      {
                        _key: "tips",
                        title: page.tipsTitle,
                        content: page.tipsContent,
                      },
                    ]}
                    portableContent
                  />
                )}
              </div>
              <div>
                <div className="primary-background content-item blockcontent">
                  <H className="h3">{page.pastandTitle} </H>
                  {page.pastandInnhold && <BlockContent blocks={page.pastandInnhold || []} />}
                  {(page.formulationItemsRefs !== null ||
                    page.formulationItemsRefs !== undefined ||
                    page.formulationItemsRefs !== [] ||
                    page.formulationItemsRefs) && (
                    <ul className={styles.formulationsList}>
                      {page.formulationItemsRefs.map(p =>
                        p._id.startsWith("O") ? (
                          <p className={styles.overskrift}>{p.title}</p>
                        ) : (
                          <li>{p.title}</li>
                        )
                      )}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className={classNames("desktop-col-4", { padding: "2rem 0" })}>
              <div className={classNames(styles.illustration, { padding: "0 2rem" })}>
                {page.heroImage && <img src={page.heroImage.asset.url} alt={page.heroImage.alt} />}
                <div className={classNames(styles.textbox, styles.text1)}>{page.bubbleText1}</div>
                <div className={classNames(styles.textbox, styles.text2)}>{page.bubbleText2}</div>
              </div>
            </div>
          </Section>

          <LinkWrapper>
            <BackLink to={`/bransje/${industry.route}/temavelger`}>
              {page.backPageButtonText}
            </BackLink>
            <ForwardLink to={`/bransje/${industry.route}/${subject.route}/forbedringspunkter`}>
              {" "}
              {page.nextPageButtonText}
            </ForwardLink>
          </LinkWrapper>
        </div>
      ) : (
        <section className="content">
          <p style={{ color: "red" }}>
            Temaet <span style={{ fontWeight: 500 }}>{subject.title}</span> er ikke fylt ut i{" "}
            <span style={{ fontWeight: 500 }}>Standard Tekster</span>. Mangler innhold i{" "}
            <span style={{ fontWeight: 500 }}>Steg 1 - Varme opp og diskutere </span>
          </p>
        </section>
      )}

      {page && (
        <ZeroImportancePopup>
          <h1 style={{ marginLeft: "1rem" }}>{page.popUpTitle}</h1>
          <div className={styles.popUpContent}>
            <Button.Secondary
              type="button"
              icon="arrow"
              onClick={() => {
                setPopupOpen(false)
                navigate(`/bransje/${industry.route}#temavelger`)
              }}
              style={{ marginBottom: "3rem" }}
            >
              {page.popUpYesBtnText}
            </Button.Secondary>
            <Button.Primary
              type="button"
              icon="arrow"
              onClick={() => {
                setPopupOpen(false)
              }}
            >
              {page.popUpNoBtnText}
            </Button.Primary>
          </div>
        </ZeroImportancePopup>
      )}
    </>
  )
}

Introduksjon.propTypes = {
  industry: IndustryType.isRequired,
  subject: SubjectType.isRequired,
}

export default withPageDependencies(Introduksjon)

import React from "react"
import PropTypes from "prop-types"
import { BlockContent } from "../../components"
import { H, Section } from "../../state/levelContext"

function SimpleMeasures({ title, description, measures }) {
  return (
    <section aria-label={title}>
      <div className="content" style={{ paddingBottom: 0 }}>
        <H className="center-text h1">{title}</H>
        <div className="desktop-col-6 center-text">
          <H className="h5">
            <BlockContent blocks={description} />
          </H>
        </div>
      </div>
      <Section>
        <ul className="content" style={{ paddingTop: "0" }}>
          {measures.map(measure => (
            <li key={measure.title} className="desktop-col center-text no-dot">
              <img src={measure.imageSrc} alt={measure.imageAlt} />
              <H className="h5" style={{ marginTop: 0 }}>
                {measure.title}
              </H>
            </li>
          ))}
        </ul>
      </Section>
    </section>
  )
}

SimpleMeasures.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.object).isRequired,
  measures: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      imageSrc: PropTypes.string.isRequired,
      imageAlt: PropTypes.string,
    })
  ).isRequired,
}

export default SimpleMeasures
